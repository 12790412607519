
import { Component, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import Calendar from "@/views/Calendar.vue";

function leftClick(e: any) {
  return e.button === 0;
}

type reservation = {
  id: number | null;
  profesional: string;
  horario: string;
  horario_termino: string;
};

type reservationData = Array<reservation>;

@Component({
  components: {
    Calendar
  }
})
export default class SearchService extends Mixins(FormValidator) {
  services = [];
  categorias = [];
  options_services: any[] = [];
  service_selected: any = "";
  service_description: string = "";
  motive_selected: any = {};
  category_selected: any = {};
  filter_string: string = "";
  today = 0;
  today_year = 0;
  yesterday = "2020-12-31";
  selected_date = "";
  dates_dialog = false;
  open_dates: Array<any> = [];
  selected_open_date: any = "";
  available_days_: Array<any> = [];
  arrayReservasUsuarios: Array<any> = [];
  private todayDate: any = "";
  private active: Boolean = false;
  private idSelected: any = null;
  private loading: any = null;
  reservationPagination = {
    sortBy: "desc",
    descending: false,
    page: 1,
    rowsPerPage: 15
  };

  reservationSeparator = "vertical";
  reservationVisibleCols = ["desc", "profesional"];
  reservationCols = [
    { name: "id", field: "id" },
    {
      name: "desc",
      required: true,
      label: "Horario de Atención",
      field: (row: any) => [row.horario, row.horario_termino].join(" - "),
      headerClasses: "text-black"
    },
    {
      name: "profesional",
      required: true,
      label: "Ejecutivo de atención",
      field: "profesional",
      headerClasses: "text-black",
      style: "padding: 10px"
    }
  ];
  reservationData: reservationData = [];
  selectedReservationArray: Array<any> = [];
  selectedReservation: reservation = {
    id: null,
    profesional: "",
    horario: "",
    horario_termino: ""
  };

  private beforeCreate() {
    this.$q.loading.show();
  }

  private getSelectedRowsLabel() {
    return "Horario seleccionado";
  }

  private getPaginationLabel(
    firstRowIndex: number,
    endRowIndex: number,
    totalRowsNumber: number
  ) {
    return firstRowIndex + "-" + endRowIndex + " de " + totalRowsNumber;
  }

  private async getServiceDateDescription() {
    await this.$axios
      .get("servicios", {
        params: {
          filter: {
            where: { id: Number(this.$route.params.service_id) }
          }
        }
      })
      .then(response => {
        if (response.data.length > 0) {
          this.$store.dispatch("storeService", response.data[0]);
        }
        this.$axios
          .get("/servicio-metas", {
            params: {
              filter: {
                where: {
                  and: [
                    { id_servicio: this.$store.state.ticket.service.id },
                    { meta_key: "reservation_description" }
                  ]
                }
              }
            }
          })
          .then(response => {
            this.service_description = response.data.meta_value;
          })
          .catch(error => {
            // capturar error 400 si es que el store no envió correctamente el
            // id_servicio
          });
      })
      .catch(error => {});
  }

  private getReservas(
    idRerserva: any,
    inicio: any,
    termino: any,
    profesional: any
  ) {
    if (this.selectedReservationArray.length == 0) {
      this.selectedReservationArray.push({
        id: idRerserva,
        profesional: profesional,
        horario: inicio,
        horario_termino: termino
      });
      this.idSelected = idRerserva;
    } else {
      for (let i = 0; i < this.selectedReservationArray.length; i++) {
        if (this.selectedReservationArray[i].id != idRerserva) {
          this.selectedReservationArray = [];
          this.selectedReservationArray.push({
            id: idRerserva,
            profesional: profesional,
            horario: inicio,
            horario_termino: termino
          });
          this.idSelected = idRerserva;
        } else {
          this.selectedReservationArray = [];
          this.idSelected = "";
        }
      }
    }
  }

  private toStepTwo() {
    this.selectedReservation = this.selectedReservationArray[0];
    let payload = {
      service: this.$route.params.service_id,
      start_date: this.selectedReservation.horario,
      end_date: this.selectedReservation.horario_termino,
      date: this.humanDate(this.selected_date),
      id_date: this.selectedReservation.id
    };
    this.$store.dispatch(
      "submitProfesional",
      this.selectedReservation.profesional
    );
    this.$store.dispatch("setStateToTwoDate", payload);
  }

  private toStepTwoOld() {
    let payload = {
      service: this.$route.params.service_id,
      start_date: this.humanTime(this.selected_open_date.hora_inicial),
      end_date: this.humanTime(this.selected_open_date.hora_final),
      date: this.humanDate(this.selected_date),
      id_date: this.selected_open_date.id
    };
    this.$store.dispatch("setStateToTwoDate", payload);
  }

  private humanDate(date: any) {
    return (
      date.split("")[8] +
      date.split("")[9] +
      "-" +
      date.split("")[5] +
      date.split("")[6] +
      "-" +
      date.split("")[0] +
      date.split("")[1] +
      date.split("")[2] +
      date.split("")[3]
    );
  }

  private isToday(arg: Date) {
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    arg.setHours(0, 0, 0, 0);
    return arg.getTime() == now.getTime();
  }

  private getMaxReservationTime() {
    let now = new Date();
    return [now.getHours() - 4, now.getMinutes(), now.getSeconds()].join(":");
  }

  private showDates(values: any) {
    let id_servicio = Number(this.$route.params.service_id);
    let id_usuario = Number(values.id_user);
    let day = new Date(values.fecha);

    let dayString = [
      day.getUTCFullYear(),
      day.getUTCMonth() + 1,
      day.getUTCDate()
    ].join("-");

    this.$axios
      .get("reserva-usuarios/listar", {
        params: {
          fecha_inicio: dayString,
          id_servicio: id_servicio,
          id_usuario: id_usuario
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.reservationData = response.data;

        if (this.reservationData.length <= 0) {
          this.$q.notify({
            message: "No hay horarios disponibles para la fecha seleccionada",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        } else this.dates_dialog = true;
      })
      .catch(error => {
        this.$q.loading.hide();
        this.$q.notify({
          message: "No hay horarios disponibles para la fecha seleccionada",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      });
  }

  private getReservasUsuarios() {
    this.$q.loading.show();
    this.$axios
      .get("reservas/usuarios/servicio", {
        params: {
          id: this.$route.params.service_id
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.arrayReservasUsuarios = response.data;
        if (this.arrayReservasUsuarios.length > 0) {
          this.loading = true;
        } else {
          this.loading = false;
        }

        this.$q.loading.hide();
      })
      .catch(error => {
        if (error.response && error.response.status != 401) {
          this.$q.loading.hide();
          this.$q.notify({
            message: this.$t("notification.problem") as string,
            color: "principal",
            position: "top",
            timeout: 5000
          });
        }
      });
  }

  private dataUser(values: any) {
    this.selected_date = values.fecha;
    this.showDates(values);
  }

  private humanDateTime(date: any) {
    let date_object = new Date(date);
    return date_object
      .toLocaleString("es-CL", { timeZone: "UTC" })
      .slice(0, -3);
  }

  private humanTime(date: any) {
    let date_object = new Date(date);
    let date_time = date_object
      .toLocaleString("es-CL", { timeZone: "UTC" })
      .slice(0, -3);
    return date_time.split(" ")[1];
  }

  private calendarNext() {
    if (this.today >= 12) {
      this.today = 1;
      this.today_year += 1;
    } else {
      this.today += 1;
    }
    (this.$refs.calendar as any).next();
  }

  private calendarPrev() {
    if (this.today <= 1) {
      this.today = 12;
      this.today_year -= 1;
    } else {
      this.today -= 1;
    }
    (this.$refs.calendar as any).prev();
  }

  private stepOneSubmit() {
    if (Object.keys(this.service_selected).length) {
      let ticket: any = {
        step: 2,
        service_id: this.service_selected.id,
        service_name: this.service_selected.nombre,
        motive: this.motive_selected.value
      };
      this.$store.dispatch("submitRequestStepOne", ticket);
    } else {
      this.$q.notify({
        message: this.$t("notification.no_service_selected") as string,
        color: "principal",
        position: "top",
        timeout: 5000
      });
    }
  }

  private moreHelpRequested() {
    this.stepOneSubmit();
  }

  private async scrollToCategory(category_id: any) {
    let expansion_item = "expansion-" + category_id;
    let options = {
      behavior: "smooth",
      alignToTop: true
    };
    //sorry
    await new Promise(resolve => setTimeout(resolve, 375));
    this.$nextTick(() => {
      document.getElementById(expansion_item)!.scrollIntoView(options as any);
    });
  }

  private selectService(service: any): void {
    if (service) {
      this.service_selected = service;
      (this.$refs as any).DialogService.dialog = true;
    }
  }

  private boldCoincidence(str: string, query: string): string {
    const n = str.toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
      return str; // bail early
    }
    return (
      str.substr(0, x) +
      "<b>" +
      str.substr(x, q.length) +
      "</b>" +
      str.substr(x + q.length)
    );
  }

  private filterService(val: string, update: any) {
    this.filter_string = val;
    if (val == null) {
      return;
    }
    if (val === "") {
      update(() => {
        this.options_services = this.services;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_services = this.services.filter((service: any) => {
        let filtered = service.nombre.toLowerCase().indexOf(value) > -1;
        return filtered;
      });
    });
  }

  private get monthName() {
    switch (this.today) {
      case 1: {
        return "Enero";
      }
      case 2: {
        return "Febrero";
      }
      case 3: {
        return "Marzo";
      }
      case 4: {
        return "Abril";
      }
      case 5: {
        return "Mayo";
      }
      case 6: {
        return "Junio";
      }
      case 7: {
        return "Julio";
      }
      case 8: {
        return "Agosto";
      }
      case 9: {
        return "Septiembre";
      }
      case 10: {
        return "Octubre";
      }
      case 11: {
        return "Noviembre";
      }
      case 12: {
        return "Diciembre";
      }
      default: {
        return "Out of range";
      }
    }
  }

  private getHora(value: any) {
    const newValue = value.split(":");
    return newValue[0] + ":" + newValue[1];
  }

  private url_frontoffice = process.env.VUE_APP_FRONT_OFFICE_URL as string;

  private routerGo(name: any) {
    if (name && name != "") {
      if (name == "newrequest") {
        window.open(this.url_frontoffice, "_self");
      } else if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          this.$q.notify({
            message: "Ha ocurrido un error",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
      }
    }
  }

  private mounted() {
    this.$gtag.event(
      "Selección Reserva:  " + this.$store.state.ticket.service.name,
      {
        event_label: "Selección Reserva",
        event_category: "test",
        value: 1
      }
    );
    window.scroll(0, 0);
    this.$q.loading.hide();
    let thisDay = new Date();
    let yesterday = new Date(thisDay);

    this.todayDate = [
      thisDay.getFullYear(),
      thisDay.getMonth() + 1,
      thisDay.getDate()
    ].join("-");
    this.today_year = thisDay.getFullYear();
    this.today = thisDay.getMonth() + 1;
    yesterday.setDate(yesterday.getDate() - 1);
    this.yesterday = yesterday.toISOString().split("T")[0];
    this.getServiceDateDescription();
    this.getReservasUsuarios();
  }
}
